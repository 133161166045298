
.home, .page {
    margin: 2vh auto;
    width: 96vh;
}
.galerie {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    img {
        margin-bottom: 1em;
    }
    &.col3 img {
        max-width: 30vh;
    }
    &.col2 img {
        max-width: 46vh;
    }
    &.col1 {
        justify-content: center;

        img {
            max-width: 96vh;
        }
    }
}
.cell {
    display: flex;
    align-items: center;
    padding: 1vh;
}

/**
 * Site header
 */
.site-header {
    .wrapper {
        display: flex;
        justify-content: space-between;
    }
}

.site-nav {
    display: flex;
    align-items: center;

    .page-link {
        font-size: 2.5em;
        color: $pourpre;
        text-decoration: none;
        font-family: $titleFont;
        font-weight: bold;
        margin-right: 0.5em;

        &::before {
            content: ' ';
            background-color:$violet;
            display: inline-block;
            height: 0.75em;
            width: 0.75em;
            margin-right: 0.25em;
        }
        &:not(:first-child) {
        }
    }
}

/**
 * Site footer
 */
.site-footer {
    border-top: $grisFonce 1px solid;
    margin-top: 1em;
    padding-top: 0.5em;
    display: flex;
    justify-content: space-between;

    a {
        color: $pourpre;
        text-decoration: none;
    }
    .social-media-list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;

    }
    .footer-nav a {
        margin-right: 1em;

        &::before {
            content: ' ';
            background-color:$violet;
            display: inline-block;
            height: 0.75em;
            width: 0.75em;
            margin-right: 0.25em;
        }
    }

}
.icon {
    svg {
        width: 1em;

        path {
            fill: $violet;
        }
    }
}

/**
 * Home
 */

.grid-home {
    display: grid;
    grid-template-columns: repeat(11, 7.8vh);
    grid-template-rows: repeat(11, 7.8vh);
    grid-gap: 1vh;

    .texte, .image {
        display: flex;
        align-items: center;
        justify-content: center;
    }
 }
#logo {
    grid-column: 1/4;
    grid-row: 3/6;
}
#graphiste {
    grid-column: 7/10;
    grid-row: 1/4;
}
#integrateur {
    grid-column: 9/12;
    grid-row: 6/9;
}
#title {
    grid-column: 4/9;
    grid-row: 4/9;
    flex-direction: column;
}
#menu-home {
    grid-column: 5/8;
    grid-row: 9/12;
}
#RS {
    grid-column: 8/10;
    grid-row: 9/11;
}
#empty1 {
    grid-column: 4;
    grid-row: 3;
}
#empty2 {
    grid-column: 5/7;
    grid-row: 2/4;
}
#empty3 {
    grid-column: 10;
    grid-row: 3;
}
#empty4 {
    grid-column: 9/11;
    grid-row: 4/6;
}
#empty5 {
    grid-column: 2/4;
    grid-row: 6/8;
}
#empty6 {
    grid-column: 3;
    grid-row: 8;
}
#empty7 {
    grid-column: 3/5;
    grid-row: 9/11;
}
#empty8 {
    grid-column: 10;
    grid-row: 9;
}
#empty9 {
    grid-column: 8;
    grid-row: 11;
}

/**
 * Page
 */

.page-title {
    display: flex;
    align-items:center;
}
.site-title {
    margin-right: 10px;
}
.intro {
    display: flex;
    align-items: center;

    svg {
        min-width: 20vw;
    }
}

/**
 * CV
 */

.quote {
    font-family: $titleFont;
    font-style: italic;
    font-size: 1.4em;
    margin: 1.5em 0;

    p {
        margin: 0.5em 0;
    }
    .right {
        text-align: right;
    }
}
.grid-cv {
    display: flex;
    justify-content: space-between;

    .cell {
        width: 32%;
        aspect-ratio: 1/1;
    }
}
/**
 * Portfolio
 */
.grid-portfolio {
    display: grid;
    grid-template-columns: repeat(7, 12vh);
    grid-template-rows: repeat(10, 12vh);
    grid-gap: 1vh;

    .cell:nth-of-type(1){
        grid-column: 1/4;
        grid-row: 1/4;
    }
    .cell:nth-of-type(2){
        grid-column: 4/6;
        grid-row: 2/4;
    }
    .cell:nth-of-type(3){
        grid-column: 6/8;
        grid-row: 3/5;
    }
    .cell:nth-of-type(4){
        grid-column: 1/3;
        grid-row: 4/6;
    }
    .cell:nth-of-type(5){
        grid-column: 3/6;
        grid-row: 4/7;
    }
    .cell:nth-of-type(6){
        grid-column: 6/8;
        grid-row: 5/7;
    }
    .cell:nth-of-type(7){
        grid-column: 1/3;
        grid-row: 6/8;
    }
    .cell:nth-of-type(8){
        grid-column: 3/5;
        grid-row: 7/9;
    }
    .cell:nth-of-type(9){
        grid-column: 5/8;
        grid-row: 7/10;
    }
    .cell:nth-of-type(10){
        grid-column: 3/5;
        grid-row: 9/11;
    }
    .image {
        padding:0;

         a {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
        }
    }
}

/**
 * Page content
 */
.page-content {
}

.page-heading {
}

.post-list {
    margin: 1.5em 0;
    padding: 0;
    list-style: none;

    > li:not(:last-of-type) {
        border-bottom: 1px solid $grisFonce;
        margin-bottom: 1em;
    }
    h1 {
        margin: 0.1em 0 0.5em;

        &:not(:first-of-type) {
            color: $pourpre;
            font-style: italic;
            font-size: 1.5em;
        }
    }
    img {
        max-width: 80%;
        max-height: 90vh;
        display: block;
        margin: auto;
        mix-blend-mode: multiply;
    }
}

.post-meta {
    font-style: italic;
}

.post-link {
    color: $pourpre;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

/**
 * Posts
 */
.post-header {
}

.post-title {
    color: $pourpre;
}

h2 {
    color: $violet;
}

.post-content {

    h1 {
        color: $pourpre;
        font-style: italic;
    }

    h3 {
        font-style: italic;
    }

    h4 {
    }
    img {
        max-width: 80%;
        max-height: 90vh;
        display: block;
        margin: auto;
        mix-blend-mode: multiply;
    }
}


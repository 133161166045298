@charset "utf-8";

// Variables
$pourpre: #b10a2a;
$pourpreFonce: #761426;
$rose: #b74e61;
$violet: #5d2742;
$grisFonce: #2a2a2a;
$blanc: #eee;
$baseFont: 'open_sansregular';
$titleFont: 'roboto_slabregular';


// Fonts
@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/OpenSans-Regular-webfont.eot');
    src: url('../fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-Regular-webfont.woff') format('woff'),
         url('../fonts/OpenSans-Regular-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'roboto_slabregular';
    src: url('../fonts/RobotoSlab-Regular-webfont.eot');
    src: url('../fonts/RobotoSlab-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/RobotoSlab-Regular-webfont.woff') format('woff'),
         url('../fonts/RobotoSlab-Regular-webfont.ttf') format('truetype'),
         url('../fonts/RobotoSlab-Regular-webfont.svg#roboto_slabregular') format('svg');
    font-weight: normal;
    font-style: normal;

}


// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;

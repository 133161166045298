/**
 * Basic styling
 */
 * {
    box-sizing: border-box;
 }
 body {
    font-family: $baseFont;
    background-color: $blanc;
 }
 .grid, .grid-home, .grid-cv {
    .pourpre {
        background-color: $pourpre;
    }
    .pourpreFonce {
        background-color: $pourpreFonce;
    }
    .violet {
        background-color: $violet;
    }
    .rose {
        background-color: $rose;
    }
    .image {
        background-position: center center;
        background-size: 100%;
    }
 }
 .cell {
    color: $blanc;
    outline: solid 2px $blanc;

    a {
        color: $blanc;
        text-decoration: none;
    }
    img {
        max-width: 100%;
        max-height: 100%;
    }
 }
#title {
    text-align: center;
    font-family: $titleFont;
    font-weight: bold;
    font-size: 7vh;
}
#menu-home{
    font-size: 3vh;

    ul {
        list-style-position: outside;
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
}
a.main-link {
    font-family: $titleFont;
    font-weight: bold;
    font-size: 3.5vh;
}
.small {
    font-size: 0.85em;
}
.grid-portfolio {
    .image a {
        font-family: $titleFont;
        font-size: 1.25em;
        font-weight: bold;
        text-align: center;

        span {
            padding: 1vh;
            opacity: 0%;
        }
    }
    .image a:hover {
        background-color: $pourpre;
        opacity: 80%;

        span {
            opacity: 100%;
        }
    }
}
ul {
    list-style-type: square;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: 0.5em;
}

/**
 * Images
 */
p {
    img {
    }
}

/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
}

/**
 * Lists
 */
ul, ol {
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}

/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-family: $titleFont;
}
h1 {
    color: $pourpre;
}
h2 {
    color: $violet;
}

h3 {
    color: $pourpre;
}

/**
 * Links
 */
a {
    color: $pourpre;

    &:visited {
    }

    &:hover {
    }
}

/**
 * Blockquotes
 */
blockquote {

    > :last-child {
    }
}

/**
 * Code formatting
 */
pre,
code {
}

code {
}

pre {

    > code {
    }
}
.wrapper-col {
    display: flex;
    padding: 0 10px;

    .col {
        padding: 0 5px;

        img {
            max-width: 100%;
        }
        p {
            text-align: center;
        }
    }
    .col1-2 {
        @extend .col;
        flex-basis: 50%;
    }
    .col1-3 {
        @extend .col;
        flex-basis: 33%;
    }
}

/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

.texte ul {
    margin: 0 0 40px 5%;

    li {
        margin: 10px 0;
    }
    span {
        font-weight: bold;
        color: $pourpre;
    }
}